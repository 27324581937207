<template>
  <v-app style="background-color:#e8eff2;">
    <v-app-bar
      app
      elevation="0"
      color="#FFFFFF"
      style="border-bottom: 1px solid rgba(243, 245, 247, 1)"
    >
      <v-toolbar-title class="pt-1">
        <img
          src="@/assets/logo_onebox-horizontal-png.png"
          width="95"
          height="40"
          alt="One Box"
        />
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items v-if="havefolder === true">
        <v-btn
          color="primary"
          fab
          icon
          @click="checkdownloadall(mainfolder)"
          v-if="resolutionScreen < 500 && permission !== '01'"
        >
          <v-icon large>mdi-folder-download</v-icon>
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="checkdownloadall(mainfolder)"
          v-else-if="resolutionScreen >= 500 && permission !== '01'"
        >
          <!-- <span> -->
            <v-icon medium>mdi-folder-download</v-icon>
            &nbsp; {{ " " + $t("publicpage.dowload") }}
          <!-- </span> -->
        </v-btn>
      </v-toolbar-items>
      <v-divider inset vertical></v-divider>
      <v-toolbar-items v-if="filedata.status_private_share === true || folder_share.private_share === true" :class="resolutionScreen < 500 ? 'mr-n3' : 'mr-2'">
        <v-list-item-avatar
          v-if="dataCitizenProfile.picture == null"
          size="80"
          class="mr-0"
        >
          <v-avatar size="45">
            <img src="@/assets/user.png" alt="John" />
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-avatar size="80" class="mr-0" v-else>
          <v-avatar size="45" style="border: solid 1px #00000061">
            <img
              :src="
                'data:image/jpeg;base64,' + dataCitizenProfile.picture
              "
              alt="John"
            />
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-if="resolutionScreen > 500">{{ $t("default") === "th" ? (dataCitizenProfile.first_name_th + " " + dataCitizenProfile.last_name_th) :(dataCitizenProfile.first_name_eng + " " + dataCitizenProfile.last_name_eng) }}</v-list-item-title>
        </v-list-item-content>
      </v-toolbar-items>
      <v-toolbar-items>
        <v-menu transition="slide-y-transition" bottom>
          <template v-slot:activator="{ on }">
            <v-btn fab text v-on="on" small>
              <flag :iso="defaultflag" :squared="false" />
            </v-btn>
          </template>
          <v-list nav dense>
            <v-list-item
              v-for="(item, i) in language"
              :key="i"
              @click="changelang(item.data)"
            >
              <v-list-item-action>
                <flag :iso="item.flag" :squared="false" />
              </v-list-item-action>
              <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>
    <v-layout
      row
      wrap
      fill-height
      align-center
      justify-center
      v-if="loading === true"
    >
      <v-flex lg6 xs11>
        <div class="text-center">
          <v-progress-circular
            indeterminate
            color="primary"
            size="100"
          ></v-progress-circular>
        </div>
      </v-flex>
    </v-layout>
    <v-layout
      row
      wrap
      fill-height
      align-center
      justify-center
      v-else-if="opencheckpassword === true"
    >
      <v-overlay :z-index="0">
        <v-flex>
          <div class="text-center">
            <v-card height="255" width="500">
              <!-- <v-card-title><v-icon large :color="filedata.file_icon[1]" class="pb-2 pr-2">{{ filedata.file_icon[0] }}</v-icon>{{filedata.file_name}}</v-card-title> -->
              <v-card-title
                >{{ $t("checkpassword.inputpassword") }} <v-spacer></v-spacer>
                <v-icon color="red" class="pb-2">lock</v-icon>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-layout row wrap align-center justify-center>
                  <v-flex xs11 lg11 ma-5>
                    <v-card class="elevation-0">
                      <v-layout row wrap justify-center>
                        <v-flex lg10 xs10>
                          <span class="mb-2">
                            {{ $t("publicpage.enterpasswordfilefolder")}}
                          </span>
                          <v-text-field
                            :label="$t('checkpassword.inputpassword')"
                            solo
                            hide-details
                            v-model="password"
                            persistent-hint
                            :disabled="clickloading"
                            multiple
                            outlined
                            dense
                            @click:append="showpassword = !showpassword"
                            :type="showpassword ? 'text' : 'password'"
                            :append-icon="
                              showpassword ? 'mdi-eye' : 'mdi-eye-off'
                            "
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <!-- <v-btn class="mt-1" @click="cancelpassword()" color="red" outlined  :disabled="clickloading">{{ $t("checkpassword.cancel") }}</v-btn> -->
                <v-btn
                  class="mt-1"
                  dark
                  color="primary"
                  @click="checkpassword(), (showpassword = false)"
                  :loading="clickloading"
                  >{{ $t("checkpassword.ok") }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </div>
        </v-flex>
      </v-overlay>
    </v-layout>
    <v-layout
      row
      wrap
      fill-height
      align-center
      justify-center
      v-else-if="havefolder === false && havefile === false"
    >
      <v-flex lg6 xs11>
        <div class="text-center">
          <v-card 
            outlined 
            style="background-color: #f2faff"
            class="elevation-2 rounded-xl"
          >
            <br />
            <v-card-text>
              <v-icon size="100" medium color="red">mdi-folder-remove</v-icon>
            </v-card-text>
            <v-card-text v-if="timeout === true" style="font-size: 20px"
              >{{ $t("publicpage.openfully")}}</v-card-text
            >
            <!-- เพิ่มตรงนี้ (หากผิดพลาด สามารถลบได้ตรงนี้) -->
            <v-card-text v-else-if="data_removed === true" style="font-size: 20px"
              >{{ $t("publicpage.filefolderdelete")}}</v-card-text
            >
            <v-card-text v-else-if="set_time === true" style="font-size: 20px"
              >{{ $t("publicpage.linktimeout")}}</v-card-text
            >
            <v-card-text
              v-else-if="permission_denied === true"
              style="font-size: 20px"
              >{{ $t("publicpage.filefoldernotfoundorcloseshare")}}
            </v-card-text>
            <v-card-text
              v-else-if="token_unavailable === true"
              style="font-size: 20px"
            >
              {{ $t("publicpage.logintoopenlink")}}</v-card-text
            >
            <v-card-text v-else style="font-size: 20px"
              >{{ $t("publicpage.filefoldernotfoundorcloseshare")}}</v-card-text
            >
            <br />
            <v-layout
              lg12
              xs12
              row
              wrap
              align-center
              justify-center
              v-if="token_unavailable === true"
            >
              <v-flex lg3 xs3>
                <v-btn
                  block
                  rounded
                  color="primary"
                  class="white--text elevation-0"
                  @click="opendialogloginsharelink = true"
                >
                  <!-- @click="$router.push({ name: 'login_sharelink_tokenunavailable', query: { nextUrl: '/public?id=' + $route.query.id } })" -->
                  {{ $t("publicpage.login")}}
                </v-btn>
              </v-flex>
            </v-layout>
            <br />
            <br />
            <v-divider></v-divider>
            <v-card-actions class="justify-center">
              <p class="pt-1">
                Powered By &nbsp;
                <img
                  @click.stop="gotohomepage()"
                  class="pt-2"
                  style="vertical-align: text-bottom"
                  src="@/assets/logo_onebox-horizontal-png.png"
                  width="65px"
                  height="35px"
                  alt="One Box2"
                />
              </p>
            </v-card-actions>
          </v-card>
        </div>
      </v-flex>
    </v-layout>
    <v-layout v-else>
      <!-- <v-app-bar
        app
        elevation="0"
        color="#FFFFFF"
        style="border-bottom: 1px solid rgba(243, 245, 247, 1)"
      >
        <v-toolbar-title class="pt-1">
          <img
            src="@/assets/logo_onebox-horizontal-png.png"
            width="95"
            height="40"
            alt="One Box"
          />
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-menu transition="slide-y-transition" bottom>
            <template v-slot:activator="{ on }">
              <v-btn fab text v-on="on" small>
                <flag :iso="defaultflag" :squared="false" />
              </v-btn>
            </template>
            <v-list nav dense>
              <v-list-item
                v-for="(item, i) in language"
                :key="i"
                @click="changelang(item.data)"
              >
                <v-list-item-action>
                  <flag :iso="item.flag" :squared="false" />
                </v-list-item-action>
                <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar-items>
      </v-app-bar> -->
      <v-content v-if="file_type === 'file'" style="background-color:#e8eff2;">
        <v-layout row wrap fill-height align-center justify-center>
          <v-flex lg8 xs11>
            <div class="text-center" v-if="loading === true">
              <v-progress-circular
                indeterminate
                color="primary"
                size="100"
              ></v-progress-circular>
            </div>
            <div class="text-center" v-else-if="havefile === true">
              <v-card
                outlined
                style="background-color: #f2faff"
                class="elevation-2 rounded-xl"
              >
                <br />
                <v-card-text>
                  <img src="@/assets/package.png" width="150" height="150" />
                </v-card-text>
                <v-card-text style="font-size: 20px">
                  <b>{{ $t("publicpage.filename") }}:</b>
                  {{ filedata["filename"] }}
                </v-card-text>
                <v-card-text style="font-size: 20px">
                  <b>{{ $t("publicpage.filesize") }}:</b>
                  {{ fn_calculate_size_new(filedata["size_file"]) }}
                </v-card-text>
                <v-card-text>
                  <v-btn
                    v-if="permission !== '01'"
                    color="primary"
                    depressed
                    :disabled="snackbardowload"
                    @click="checkdownloadfile(filedata)"
                    >{{ $t("publicpage.dowload") }}</v-btn
                  >
                  <v-btn
                    class="ma-2"
                    color="primary"
                    depressed
                    outlined
                    :disabled="snackbardowload"
                    @click="checkpreview()"
                    >{{ $t("publicpage.preview") }}</v-btn
                  >
                </v-card-text>
                <!-- <br /> -->
                <!-- ข้อมูลการแชร์ one line -->
                <v-card-text style="font-size: 16px" class="text-center">
                  <span>
                    <v-icon v-if="filedata['business_shared_th'] === 'ไดร์ฟส่วนตัว'" size="18">mdi-weather-cloudy</v-icon>
                    <v-icon v-else size="18">mdi-office-building</v-icon>
                    <b> {{ $t("publicpage.sharedfrom") }}:</b> {{ $t("default") === "th" ? filedata["business_shared_th"] : filedata["business_shared_eng"]}}
                  </span>
                  <span v-if="resolutionScreen >= 400"> | </span>
                  <br v-else />
                  <span>
                    <v-icon size="18">mdi-account-circle-outline</v-icon>
                    <b> {{ $t("publicpage.sharedby") }}:</b> {{ $t("default") === "th" ? filedata["name_shared_th"] : filedata["name_shared_eng"]}}
                  </span>
                  <span v-if="resolutionScreen >= 400"> | </span>
                  <br v-else />
                  <span>
                    <v-icon size="18">mdi-timetable</v-icon>
                    <b> {{ $t("publicpage.shareddate") }}:</b> {{ filedata["last_dtm"] }}
                  </span>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="justify-center">
                  <p class="pt-1">
                    Powered By &nbsp;
                    <img
                      @click.stop="gotohomepage()"
                      class="pt-2"
                      style="vertical-align: text-bottom"
                      src="@/assets/logo_onebox-horizontal-png.png"
                      width="65px"
                      height="35px"
                      alt="One Box2"
                    />
                  </p>
                </v-card-actions>
              </v-card>
            </div>
            <div class="text-center" v-else>
              <v-card
                outlined
                class="elevation-2"
                style="background-color: #f2faff"
              >
                <br />
                <v-card-text>
                  <v-icon size="100" medium color="red">mdi-file-cancel</v-icon>
                </v-card-text>

                <v-card-text style="font-size: 20px"
                  >{{$t("publicpage.filefoldernotfoundorcloseshare")}}</v-card-text
                >
                <br />
                <v-divider></v-divider>
                <v-card-actions class="justify-center">
                  <p class="pt-1">
                    Powered By &nbsp;
                    <img
                      @click.stop="gotohomepage()"
                      class="pt-2"
                      style="vertical-align: text-bottom"
                      src="@/assets/logo_onebox-horizontal-png.png"
                      width="65px"
                      height="35px"
                      alt="One Box2"
                    />
                  </p>
                </v-card-actions>
              </v-card>
            </div>
          </v-flex>
        </v-layout>
        <!-- snackbardowload -->
        <!-- <div class="text-center ma-2">
          <v-snackbar v-model="snackbardowload" bottom color="white" :timeout="timeout">
            <v-list color="white" style="padding-top: 0px;">
              <v-toolbar color="primary" dense short flat fab>
                {{$t('myinboxPage.dowload.dowloading')}}
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="snackbardowload = false" fab x-small>
                  <v-icon>mdi-window-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-list-item dense style="height:40px; width:400px;">
                <v-list-item-content>
                  <v-list-item-title>{{namedowload}}</v-list-item-title>
                </v-list-item-content>
                <v-progress-circular
                  :rotate="90"
                  :size="35"
                  :width="3"
                  :value="percentCompleted"
                  color="teal"
                  style="margin-left:25px"
                >&nbsp; {{ percentCompleted }}</v-progress-circular>
              </v-list-item>
            </v-list>
          </v-snackbar>
        </div> -->
        <dialogsnackbermultidowload
          :show="opensnackbarmultidownload"
          @closedialog="opensnackbarmultidownload = false"
          :percent="percent"
          :namedowload="namedowload"
          :btsdownload="btsdownload"
        ></dialogsnackbermultidowload>
        <previewpublicfile
          :show="openpreviewpublicfile"
          :filedata="currentfile"
          :parentfolder="parentfolder"
          @closepreviewfile="openpreviewpublicfile = false"
          @openpreviewfile="openpreviewpublicfile = true"
        ></previewpublicfile>
      </v-content>

      <v-content v-if="file_type === 'folder'" style="background-color:#e8eff2;">
        <!-- <v-app-bar app elevation="0" v-if="havefolder === true" color="primary">
          <v-toolbar-title class="pt-1">
            <img
              src="@/assets/logo_onebox-horizontal-white.png"
              width="95"
              height="40"
              alt="One Box"
            />
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              fab
              icon
              @click="checkdownloadall(mainfolder)"
              v-if="resolutionScreen < 500 && permission !== '01'"
              dark
            >
              <v-icon large>mdi-folder-download</v-icon>
            </v-btn>
            <v-btn
              text
              @click="checkdownloadall(mainfolder)"
              v-else-if="resolutionScreen >= 500 && permission !== '01'"
              dark
            >
              <span>
                <v-icon medium>mdi-folder-download</v-icon>
                &nbsp; &nbsp;{{ " " + $t("currentRClick.downloadfile") }}
              </span>
            </v-btn>
          </v-toolbar-items>
        </v-app-bar> -->

        <v-content>
          <!-- <v-divider></v-divider> -->
          <v-layout
            row
            wrap
            fill-height
            align-center
            justify-center
            v-if="loading === true"
          >
            <v-flex lg6 xs11>
              <div class="text-center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  size="100"
                ></v-progress-circular>
              </div>
            </v-flex>
          </v-layout>
          <v-layout
            row
            wrap
            fill-height
            align-center
            justify-center
            v-else-if="havefolder === false"
          >
            <v-flex lg6 xs11>
              <div class="text-center">
                <v-card outlined>
                  <br />
                  <v-card-text>
                    <v-icon size="100" medium color="red"
                      >mdi-folder-remove</v-icon
                    >
                  </v-card-text>
                  <v-card-text v-if="timeout === true" style="font-size: 20px"
                    >{{ $t("publicpage.openfully") }}</v-card-text
                  >
                  <v-card-text v-if="set_time === true" style="font-size: 20px"
                    >{{ $t("publicpage.linktimeout") }}</v-card-text
                  >
                  <v-card-text
                    v-else-if="permission_denied === true"
                    style="font-size: 20px"
                    >
                    {{ $t("publicpage.notaccesslink") }}
                    </v-card-text
                  >
                  <v-card-text v-else style="font-size: 20px"
                    >{{ $t("publicpage.filefoldernotfoundorcloseshare") }}</v-card-text
                  >
                  <br />
                  <v-divider></v-divider>
                  <v-card-actions class="justify-center">
                    <p class="pt-1">
                      Powered By &nbsp;
                      <img
                        @click.stop="gotohomepage()"
                        class="pt-2"
                        style="vertical-align: text-bottom"
                        src="@/assets/logo_onebox-horizontal-png.png"
                        width="65px"
                        height="35px"
                        alt="One Box2"
                      />
                    </p>
                  </v-card-actions>
                </v-card>
              </div>
            </v-flex>
          </v-layout>

          <v-container v-else style="overflow-y: hidden;" class="mt-n10">
            <v-layout row wrap justify-center align-center>
              <v-flex lg12 xs11>
                <v-card 
                  outlined
                  style="background-color: #f2faff"
                  class="elevation-2 px-8 py-6 rounded-xl"
                >
                  <!-- ข้อมูลการแชร์ one line-->
                  <v-card-text class="text-right mt-n5 pb-0" style="font-size:16px;">
                    <span>
                      <v-icon v-if="folder_share['business_shared_th'] === 'ไดร์ฟส่วนตัว'" size="18">mdi-weather-cloudy</v-icon>
                      <v-icon v-else size="18">mdi-office-building</v-icon>
                      <b> {{ $t("publicpage.sharedfrom") }}:</b> {{ $t("default") === "th" ? folder_share["business_shared_th"] : folder_share["business_shared_eng"]}}
                    </span>
                    <span v-if="resolutionScreen >= 400"> | </span>
                    <br v-else />
                    <span>
                      <v-icon size="18">mdi-account-circle-outline</v-icon>
                      <b> {{ $t("publicpage.sharedby") }}:</b> {{ $t("default") === "th" ? folder_share["name_shared_th"] : folder_share["name_shared_eng"]}}
                    </span>
                    <span v-if="resolutionScreen >= 400"> | </span>
                    <br v-else />
                    <span>
                      <v-icon size="18">mdi-timetable</v-icon>
                      <b> {{ $t("publicpage.shareddate") }}:</b> {{ folder_share["last_dtm"]}}
                    </span>
                  </v-card-text>
                  <!-- <v-overlay :value="processloader" absolute color="grey lighten-4" dark>
                  <v-progress-circular indeterminate size="64" color="blue"></v-progress-circular>
                  <br />
                  <br />
                  <span style="color: #2196F3">&nbsp; &nbsp; loading</span>
                </v-overlay> -->
                  <v-list dense class="elevation-0 py-0" style="background-color: #f2faff">
                    <v-list-item class="text-left">
                      <v-btn
                        small
                        color="primary"
                        @click="backtopreventfolder()"
                        v-if="navigate.length > 1"
                        fab
                        class="mr-3"
                      >
                        <v-icon>keyboard_backspace</v-icon>
                      </v-btn>
                      <v-list-item-avatar class="text-center">
                        <v-avatar color="primary" size="35">
                          <v-icon dark size="20">folder</v-icon>
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-breadcrumbs :items="navigate" divider=">">
                          <template v-slot:item="props">
                            <v-breadcrumbs-item
                              style="cursor: pointer"
                              @click="gotofolder(props.item.text[1])"
                            >
                              <span
                                style="
                                  color: #1565c0;
                                  font-size: 20px;
                                  font-weight: 700;
                                "
                                >{{ props.item.text[0] }}</span
                              >
                            </v-breadcrumbs-item>
                          </template>
                        </v-breadcrumbs>
                      </v-list-item-content>
                    </v-list-item>
                    <!-- <v-spacer></v-spacer> -->
                    <!-- <v-toolbar-items>
                      <v-btn
                        fab
                        small
                        text
                        @click="backtopreventfolder()"
                        v-if="navigate.length > 1"
                      >
                        <v-icon>keyboard_backspace</v-icon>
                      </v-btn>
                    </v-toolbar-items> -->
                  </v-list>
                  <!-- <v-divider></v-divider> -->
                  <v-card-text v-if="loadingdata === true" class="text-center">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                      size="50"
                    ></v-progress-circular>
                  </v-card-text>
                  <v-card-text v-else id="thiscontainer_publicfolder" class="mt-n12">
                    <br />
                    <v-layout row wrap class="pt-0 pb-0">
                      <!-- <v-flex lg2 xs12 class="pl-5 pr-5">
                      <v-select
                        prepend-inner-icon="sort"
                        hide-details
                        outlined
                        dense
                        v-model="size"
                        :items="listsize"
                        class="text-center"
                      >
                        <template v-slot:item="{ item }">
                          <div class="text-center">
                            <span style="font-size: 17px;">{{ item }}</span>
                          </div>
                        </template>
                        <template v-slot:selection="{ item }">
                          <div class="text-center">
                            <span style="font-size: 17px;">{{ item }}</span>
                          </div>
                        </template>
                      </v-select>
                      <br />
                    </v-flex> -->
                      <v-flex class="text-right mb-3">
                        <v-chip class="ma-2" color="primary" text-color="white">
                          <v-icon left>folder</v-icon>

                          {{ countfolder + " " + $t("folders") }}
                        </v-chip>
                        <v-chip class="ma-2" color="primary" text-color="white">
                          <v-icon left>mdi-file</v-icon>

                          {{ countfile + " " + $t("files") }}
                        </v-chip>
                      </v-flex>
                    </v-layout>
                    <!-- <v-breadcrumbs :items="navigate"  divider=">">
                        <template v-slot:item="props">
                        <v-breadcrumbs-item
                        style="cursor: pointer;"
                            @click="gotofolder(props.item.text[1])"
                        >
                            {{ props.item.text[0] }}
                        </v-breadcrumbs-item>
                        </template>
                        </v-breadcrumbs>
                  <br>-->
                    <div v-if="resolutionScreen < 500" class="mx-n6">
                      <v-list two-line class="mb-6 pb-6" style="height: 400px; overflow-y:auto" v-if="datafolder.length > 0">
                        <v-list-item
                          v-for="item in datafolder"
                          :key="item.title"
                          style="cursor: pointer"
                          @click="checkdirectory(item)"
                        >
                          <v-list-item-avatar>
                            <v-icon
                              large
                              :color="item.file_icon[1]"
                              v-text="item.file_icon[0]"
                            ></v-icon>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title
                              v-text="item.file_name"
                            ></v-list-item-title>
                            <v-list-item-subtitle
                              v-text="fn_calculate_size_new(item.file_size)"
                            ></v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-btn
                              :disabled="permission === '01'"
                              class="elevation-0"
                              fab
                              small
                              @click="checkdownload_folder(item)"
                            >
                              <v-icon>mdi-download</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                      <v-list style="height: 300px;" class="pt-12 mb-4" v-else>
                        <v-img
                          :src="require('../assets/img/icon_empty_data.png')"
                          max-width="132"
                          max-height="150"
                          class="mx-auto"
                        />
                        <v-list-item-title
                          style="font-size: 16px; font-weight: 700; line-height: 24px;"
                          class="text-center mt-8"
                        >
                          {{$t('tablefile.empty')}}
                        </v-list-item-title>
                      </v-list>
                    </div>

                    <div v-else>
                      <v-card
                        outlined
                        class="elevation-0 rounded-xl pt-5"
                        style="border-radius:10px"
                      > 
                        <v-data-table
                          v-model="selected"
                          :headers="headers"
                          :items="datafolder"
                          :single-select="singleSelect"
                          item-key="file_id"
                          :show-select="false"
                          :no-data-text="$t('tablefile.empty')"
                          class="elevation-1"
                          :page.sync="page"
                          :items-per-page="size"
                          :hide-default-footer="true"
                          @page-count="pageCount = $event"
                          height="400"
                          fixed-header
                        >
                          <!-- <template v-slot:top>
                            <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch>
                        </template>-->
                          <template v-slot:[`header.file_type`]="{ header }">
                            <span
                              style="
                                color: #1565c0;
                                font-weight: 600;
                                font-size: 15px;
                              "
                              >{{ header.text }}</span
                            >
                          </template>
                          <template v-slot:[`header.file_name`]="{ header }">
                            <span
                              style="
                                color: #1565c0;
                                font-weight: 600;
                                font-size: 15px;
                              "
                              >{{ $t(header.text) }}</span
                            >
                          </template>
                          <template v-slot:[`header.file_date`]="{ header }">
                          <span
                            style="
                              color: #1565c0;
                              font-weight: 600;
                              font-size: 15px;
                            "
                            >{{ $t(header.text) }}</span
                          >
                          </template>
                          <template v-slot:[`header.file_size`]="{ header }">
                          <span
                            style="
                              color: #1565c0;
                              font-weight: 600;
                              font-size: 15px;
                            "
                            >{{ $t(header.text) }}</span
                          >
                          </template>

                          <!-- <template v-slot:[`header.file_count_in`]="{}">
                          <v-chip color="indigo" text-color="white">
                            <v-icon left>mdi-file-cloud</v-icon>

                            {{ totalfiles() }}
                          </v-chip>
                          </template> -->
                          <template v-slot:[`header.file_count_in`]="{header}">
                          <span
                            style="
                              color: #1565c0;
                              font-weight: 600;
                              font-size: 15px;
                            "
                            >{{ $t(header.text) }}</span
                          >
                          </template>
                          <template v-slot:[`header.file_status`]="{ header }">
                          <span
                            style="
                              color: #1565c0;
                              font-weight: 600;
                              font-size: 15px;
                            "
                            >{{ $t(header.text) }}</span
                          >
                          </template>
                          <template v-slot:item="props">
                            <!-- <tbody v-if="datafolder.lenght > 0"> -->
                              <tr
                                @dblclick="checkdirectory(props.item)"
                                style="cursor: pointer"
                              >
                                <!-- @click="gotodirectory(item)" -->
                                <td class="text-center">
                                  <v-icon
                                    large
                                    :color="props.item.file_icon[1]"
                                    style="font-weight: 100"
                                    >{{ props.item.file_icon[0] }}</v-icon
                                  >
                                </td>
                                <td>{{ props.item.file_name }}</td>
                                <td>{{ setformatdate(props.item.file_date) }}</td>
                                <td class="text-center">
                                  {{ fn_calculate_size_new(props.item.file_size) }}
                                </td>
                                <td class="text-center">
                                  <div v-if="props.item.file_type === 'folder'">
                                    <!-- <span style="font-size: 15px; color: #1A237E"><v-icon size="20" class="mt-0 pt-0" color="indigo">folder</v-icon> 0 </span> -->

                                    <v-tooltip top>
                                      <template v-slot:activator="{ on }">
                                        <span
                                          style="font-size: 15px; color: #1a237e"
                                          v-on="on"
                                        >
                                          <v-icon
                                            class="mt-n1"
                                            size="20"
                                            color="indigo"
                                            >mdi-file-cloud</v-icon
                                          >
                                          {{ props.item.file_count_in }}
                                        </span>
                                      </template>
                                      <span>{{
                                        props.item.file_count_in + " " + $t("files")
                                      }}</span>
                                    </v-tooltip>
                                  </div>
                                </td>
                                <td class="text-center">
                                  <v-btn
                                    :disabled="permission === '01'"
                                    class="elevation-0"
                                    fab
                                    small
                                    @click="checkdownload_folder(props.item)"
                                  >
                                    <v-icon>mdi-download</v-icon>
                                  </v-btn>
                                </td>
                              </tr>
                            <!-- </tbody> -->
                          </template>
                        </v-data-table>
                      </v-card>
                    </div>
                    <br />
                    <v-layout row wrap justify-center>
                      <v-flex xs11 lg11>
                        <v-pagination
                          @input="changepage()"
                          v-model="page"
                          :length="pageCount || 0"
                          color="primary"
                          :total-visible="10"
                        ></v-pagination>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-content>
        <previewfile
          :show="openpreviewfile_folder"
          :filedata_1="currentfile_folder"
          :arrayfile="datafolder"
          :folderdata="folder_share"
          userid="Shared link"
          @closepreviewfile="openpreviewfile_folder = false"
          @openpreviewfile="openpreviewfile_folder = true"
          :dataCitizenProfile="dataCitizenProfile"
        ></previewfile>
        <!-- snackbardowload -->
        <div class="text-center ma-2">
          <v-snackbar
            v-model="snackbardowload"
            bottom
            color="white"
            :timeout="timeout"
          >
            <v-list color="white" style="padding-top: 0px">
              <v-toolbar color="primary" dense short flat fab>
                {{ $t("myinboxPage.dowload.dowloading") }}
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="snackbardowload = false"
                  fab
                  x-small
                >
                  <v-icon>mdi-window-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-list-item dense style="height: 40px; width: 400px">
                <v-list-item-content>
                  <v-list-item-title>{{ namedowload }}</v-list-item-title>
                </v-list-item-content>
                <v-progress-circular
                  :rotate="90"
                  :size="35"
                  :width="3"
                  :value="percentCompleted"
                  color="teal"
                  style="margin-left: 25px"
                  >&nbsp; {{ percentCompleted }}</v-progress-circular
                >
              </v-list-item>
            </v-list>
          </v-snackbar>
        </div>

        <!-- snackbardowloadfolder -->
        <div class="text-center ma-2">
          <v-snackbar
            v-model="snackbardowloadfolder"
            bottom
            color="white"
            :timeout="timeout"
          >
            <v-list color="white" style="padding-top: 0px">
              <v-toolbar color="primary" dense short flat fab>
                {{ $t("myinboxPage.dowload.dowloading") }}
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="snackbardowloadfolder = false"
                  fab
                  x-small
                >
                  <v-icon>mdi-window-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-list-item dense style="height: 40px; width: 400px">
                <v-list-item-content>
                  <v-list-item-title>{{ namedowloadfolder }}</v-list-item-title>
                </v-list-item-content>
                <v-progress-circular
                  :rotate="90"
                  :size="35"
                  :width="3"
                  :value="percentCompleted"
                  color="teal"
                  style="margin-left: 25px"
                  >&nbsp; {{ percentCompleted }}</v-progress-circular
                >
              </v-list-item>
            </v-list>
          </v-snackbar>
        </div>
        <dialogsnackbermultidowload
          :show="opensnackbarmultidownload"
          @closedialog="opensnackbarmultidownload = false"
          :percent="percent"
          :namedowload="namedowload"
          :btsdownload="btsdownload"
        ></dialogsnackbermultidowload>
      </v-content>      
    </v-layout>
    <dialogloginsharelink
      :show="opendialogloginsharelink"
      @login_success="loginSharelinkCallback"
      @closedialog="opendialogloginsharelink = false"      
    ></dialogloginsharelink>
    <!-- <div class="text-center" >
            <v-card outlined class="elevation-2" style="background-color: #f2faff">
              <br />
              <v-card-text>
                <v-icon size="100" medium color="red">mdi-file-cancel</v-icon>
              </v-card-text>
              <v-card-text style="font-size: 20px">ไม่พบไฟล์นี้ในระบบ หรือ ไฟล์นี้ถูกปิดการแชร์</v-card-text>
              <br />
              <v-divider></v-divider>
              <v-card-action>
                <p class="pt-1" >
                  Powered By &nbsp;
                  <img
                    @click.stop="gotohomepage()"
                    class="pt-2"
                    style="vertical-align: text-bottom;"
                    src="@/assets/logo_onebox-horizontal-png.png"
                    width="65px"
                    height="35px"
                    alt="One Box2"
                  />
                </p>
              </v-card-action>
            </v-card>
          </div> -->
  </v-app>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { saveAs } from "file-saver";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import gbfdecodearraybuffer from "@/globalFunctions/decodearraybuffer";
import CryptoJS from "crypto-js";
import { mapState, mapGetters } from "vuex";
import format from "date-fns/format";
import VueCookies from "vue-cookies";
import gb_sizeDataConverter from "@/globalFunctions/sizeDataConverter";
const dialogsnackbermultidowload = () =>
  import("../components/optional/dialog-snackbarmultidowmload");
const previewpublicfile = () =>
  import("../components/optional/dialog-previewpublic");
const previewfile = () =>
  import("../components/optional/dialog-preview-public");
const dialogloginsharelink = () =>
  import("../components/optional/dialog-login-sharelink");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  data: function () {
    return {
      opendialogloginsharelink: false,
      token_unavailable: false,
      permission_denied: false,
      folder_share: [],
      namefile_folder: "",
      new_list: [],
      listdatadowload: [],
      newlist: [],
      dataIndex: "",
      overlay: false,
      password: "",
      showpassword: false,
      clickloading: false,
      headers: [
        {
          text: "#",
          align: "left",
          sortable: false,
          value: "file_type",
          width: "7%",
        },
        {
          text: "tablefile.filename",
          align: "left",
          value: "file_name",
          width: "35%",
          sortable: true,
        },
        {
          text: "tablefile.date",
          value: "file_date",
          width: "15%",
          align: "left",
          sortable: true,
        },
        {
          text: "tablefile.filesize",
          value: "file_size",
          width: "23%",
          align: "center",
          sortable: true,
        },
        {
          text: "tablefile.count",
          value: "file_count_in",
          width: "11%",
          align: "center",
          sortable: false,
        },
        {
          text: "tablefile.download",
          value: "file_status",
          width: "11%",
          align: "left",
          sortable: false,
        },
      ],
      permission: "",

      timeout: false,
      havefolder: false,
      total_data: 0,
      your_folder_id: "",
      page: 1,
      size: 30,
      selected: [],
      userid: "",
      snackbardowloadfolder: false,
      namedowloadfolder: "",
      namedowload: "",
      singleSelect: false,
      openpreviewfile: false,
      openpreviewfile_folder: false,
      loadingdata: false,
      countfolder: 0,
      countfile: 0,
      mainfolder: "",
      navigate: [],
      file_type: "",
      btsdownload: false,
      checkfilename: "",
      typefile: [],
      name: "",
      parentfolder: "",
      openpreviewpublicfile: false,
      opensnackbarmultidownload: false,
      percent: "",
      namedowload: "",
      timeout: 60000,
      percentCompleted: 0,
      snackbardowload: false,
      loading: true,
      havefile: false,
      filedata: {},
      datafolder: [],
      set_time: false,
      data_removed: false, // เพิ่มตรงนี้ (หากผิดพลาด สามารถลบได้ตรงนี้)
      // currentfile:{},
      currentfile_folder: {},
      currentfile: {
        file_icon: ["", ""],
        file_id: "",
        file_name: "",
        file_size: "",
        file_status: "",
        file_type: "",
      },
      opencheckpassword: false,
      currentopendate: "",
      defaultflag: "",
      fullname: "",
      language: [
        {
          flag: "us",
          name: "languages.english",
          data: "en",
        },
        {
          flag: "th",
          name: "languages.thai",
          data: "th",
        },
      ],
    };
  },
  components: {
    dialogsnackbermultidowload,
    previewpublicfile,
    previewfile,
    dialogloginsharelink,
  },
  computed: {
    ...mapGetters([
      "dataCitizenProfile",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        let l = this.total_data;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
  },
  methods: {
    loginSharelinkCallback() {
      console.log("login callback");
      this.opendialogloginsharelink = false      
      this.getfiledetail()
    },
    //ใช้กับ format ที่มาเป็นตัวเลข เช่น 20220921112212
    formatdatetime(_datetime) {
      if (_datetime === "" || _datetime === "-" || _datetime === undefined) {
        return "-";
      } else {
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return (
          dateday +
          "/" +
          datemonth +
          "/" +
          dateyear +
          " " +
          hour +
          ":" +
          minute 
          // +
          // ":" +
          // second
        );
      }
    },
    changepage() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 1000);

      this.loadfile_folder(this.your_folder_id);
    },
    //  cancelpassword(){
    //    this.opencheckpassword = false;
    //    this.havefile = false;
    //   this.havefolder = false;
    //  },
    checkpassword() {
      if (this.password === "") {
        Toast.fire({
          icon: "error",
          title: "กรุณากรอกรหัสผ่าน",
        });
      } else {
        this.clickloading = true;
        this.getfiledetail();
      }
    },
    check_file() {
      console.log("เข้าcheck_fileจ้า");
      // this.currentfile = file;
      console.log(this.filedata);
      this.currentfile = this.filedata;
      this.openpreviewpublicfile = true;
      // console.log("this.currentfile ***",this.currentfile);
      // console.log("this.openpreviewpublicfile",this.openpreviewpublicfile);
    },
    async checkdownloadall(item) {
      console.log(item);
      // let folder_shared = localStorage.getItem('data_id')
      let folder_shared = CryptoJS.AES.decrypt(
        localStorage.getItem("data_id"),
        CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY),
        { mode: CryptoJS.mode.ECB }
      );
      folder_shared = window.atob(folder_shared.toString(CryptoJS.enc.Utf8));
      // เก่า let token = localStorage.getItem("token") ? localStorage.getItem("token") : sessionStorage.getItem("token") ? sessionStorage.getItem("token") : VueCookies.get("token");
      let token = VueCookies.get("token") ? VueCookies.get("token") : sessionStorage.getItem("token") ? sessionStorage.getItem("token") : VueCookies.get("token");
      let token_ = token ? window.atob(token) : "";
      // let token_ = (token = window.atob(token));
      let payload = {
        folder_shared: folder_shared,
        access_token: token_,
      };
      try {
        let auth = await gbfGenerate.generateToken();
        var response = await this.axios.post(
          // process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/get_share_file_link",
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/share_link/check_sharing",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        );
        if (response.data.status === "OK") {
          console.log(response);

          this.downloadall(item);
        } else if (response.data.errorCode === "ER502") {
          this.loading = false;
          this.havefile = false;
          this.havefolder = false;
        } else {
          Toast.fire({
            icon: "error",
            title: response.data.errorCode + ":" + response.data.errorMessage,
          });
        }
      } catch (ex) {
        console.log(ex);
        this.loading = false;
        this.havefile = false;
        this.havefolder = false;
      }
    },
    async checkdownload_folder(item) {
      // let folder_shared = localStorage.getItem('data_id')
      let folder_shared = CryptoJS.AES.decrypt(
        localStorage.getItem("data_id"),
        CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY),
        { mode: CryptoJS.mode.ECB }
      );
      folder_shared = window.atob(folder_shared.toString(CryptoJS.enc.Utf8));
      // เก่า let token = localStorage.getItem("token") ? localStorage.getItem("token") : sessionStorage.getItem("token") ? sessionStorage.getItem("token") : VueCookies.get("token");
      let token = VueCookies.get("token") ? VueCookies.get("token") : sessionStorage.getItem("token") ? sessionStorage.getItem("token") : VueCookies.get("token");
      let token_ = token ? window.atob(token) : "";
      // let token_ = (token = window.atob(token));
      let payload = {
        folder_shared: folder_shared,
        access_token: token_,
      };
      try {
        let auth = await gbfGenerate.generateToken();
        var response = await this.axios.post(
          // process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/get_share_file_link",
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/share_link/check_sharing",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        );
        if (response.data.status === "OK") {
          console.log(response);

          this.downloadfile_folder(item);
        } else if (response.data.errorCode === "ER502") {
          this.loading = false;
          this.havefile = false;
          this.havefolder = false;
        } else {
          Toast.fire({
            icon: "error",
            title: response.data.errorCode + ":" + response.data.errorMessage,
          });
        }
      } catch (ex) {
        console.log(ex);
        this.loading = false;
        this.havefile = false;
        this.havefolder = false;
      }
    },
    //  async checkdownloadfile(item){
    //     // let folder_shared = localStorage.getItem('data_id')
    //     let folder_shared = CryptoJS.AES.decrypt(localStorage.getItem('data_id'), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
    //     folder_shared = window.atob(folder_shared.toString(CryptoJS.enc.Utf8))
    //     let payload = {
    //       folder_shared: folder_shared
    //     }
    //     try {
    //         let auth = await gbfGenerate.generateToken();
    //        var response = await this.axios.post(
    //       // process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/get_share_file_link",
    //       process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/share_link/check_sharing",
    //       payload,
    //       {
    //         headers: { Authorization: auth.code },
    //       }
    //     );
    //     if(response.data.status === "OK"){
    //         console.log(response);

    //         this.downloadfile(item);

    //     }else if(response.data.errorCode === "ER502"){
    //        this.loading = false;
    //        this.havefile = false;
    //        this.havefolder = false;
    //     }else{
    //         Toast.fire({
    //           icon: "error",
    //           title: response.data.errorCode + ":" + response.data.errorMessage,
    //         });
    //     }
    //     }catch(ex){
    //         console.log(ex);
    //       this.loading = false;
    //       this.havefile = false;
    //       this.havefolder = false;
    //     }

    //   },
    async checkdownloadfile(currentfile) {
      console.log("currentfile", currentfile);
      let checkfilesize = await this.fn_calculate_size(currentfile.size_file);
      // let checkfilesize = this.fn_calculate_size(currentfile.file_size);
      this.percent = 0;
      this.btsdownload = true;
      let typefile = currentfile.filename.split(".");
      console.log(typefile[1]);
      console.log("currentfile", currentfile);
      if (typefile[1] === "folder") {
        Toast.fire({
          type: "warning",
          title: "Coming Soon !!",
        });
      } else {
        if (
          typefile[1] === "pdf" &&
          currentfile.permission_share_link === "01"
        ) {
          // https://uatbox.one.th/downloads/api/v1/merge/downloads_file?file_id=...&account_id=...&user_id=...&language=th
          let payload = {
            account_id: this.dataAccountId,
            user_id: this.dataUsername,
            file_id: currentfile.id,
          };
          let url =
            process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
            "/api/v1/merge/downloads_file?user_id=" +
            this.dataUsername +
            "&file_id=" +
            currentfile.id +
            "&account_id=" +
            this.dataAccountId +
            "&language=" +
            this.$t("default");
          // this.snackbardowload = true;
          if (checkfilesize < 1.0) {
            this.opensnackbarmultidownload = true;
            let auth = await gbfGenerate.generateToken();
            this.axios
              .get(url, {
                headers: { Authorization: auth.code },
                onDownloadProgress: (progressEvent) => {
                  let progresspercent = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  console.log(this.percentCompleted);
                  this.namedowload = currentfile.filename;
                  if (progresspercent === 95) {
                    this.percentCompleted = 95;
                  } else {
                    this.percentCompleted = progresspercent;
                  }
                  this.percent = this.percentCompleted;
                },
                withCredentials: false,
                responseType: "arraybuffer",
              })
              .then((response) => {
                if (response.statusText === "OK") {
                  if (
                    parseInt(response.headers["content-lenght"]) < 700 &&
                    parseInt(response.headers["content-type"]) ===
                      "application/json"
                  ) {
                    this.opensnackbarmultidownload = false;
                    let res_error = gbfdecodearraybuffer.decodearraybuffer(
                      response.data
                    );
                    Toast.fire({
                      icon: "error",
                      title: res_error.errorMessage,
                    });
                  } else {
                    this.percentCompleted = 100;
                    this.percent = this.percentCompleted;
                    this.btsdownload = false;

                    let headers = response.headers;
                    let blob = new Blob([response.data], {
                      type: headers["content-type"],
                    });
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = currentfile.filename;
                    link.click();

                    // this.namedowload = currentfile.file_name;
                    // const blob = new Blob([response.data]);
                    // const content = response.headers["content-type"];
                    // saveAs(blob, currentfile.file_name);

                    setTimeout(() => {
                      this.opensnackbarmultidownload = false;
                      this.opensnackbar = false;
                    }, 2500);
                  }
                } else {
                  this.opensnackbarmultidownload = false;
                }
              })
              .catch((error) => {
                this.btsdownload = false;
                this.opensnackbarmultidownload = true;
                setTimeout(() => {
                  this.opensnackbarmultidownload = false;
                }, 3000);
                Toast.fire({
                  icon: "error",
                  title:
                    "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้",
                });
                console.log(error);
              });
          } else {
            this.opensnackbardownloadfile = true;
            this.btsdownload = true;
            let timeout = checkfilesize * 4000;
            window.location.assign(url);
            setTimeout(() => {
              this.opensnackbardownloadfile = false;
            }, timeout);
          }
        } else {
          console.log("เข้า 2");
          // let payload = {
          //   account_id: this.dataAccountId,
          //   user_id: this.dataUsername,
          //   file_id: currentfile.id,
          // };
          let url;
          if (currentfile["status_watermark"] === "N") {
            url =
              process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
              "/api/v2/download_file?user_id=" +
              this.dataUsername +
              "&file_id=" +
              currentfile.id;
          } else {
            url = 
              process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + 
              "/api/v1/share_link/download_file?file_id="+ 
              currentfile.id +
              "&language=" +
              this.$t("default") +
              "&link=" +
              this.$route.query.id;
            if (this.filedata.status_private_share === true || this.folder_share.private_share === true) {
              url = url + "&account_id=" + this.dataCitizenProfile.account_id;;
            }
          }
          // this.snackbardowload = true;
          if (checkfilesize < 1.0) {
            this.opensnackbarmultidownload = true;
            let auth = await gbfGenerate.generateToken();
            this.axios
              .get(url, {
                headers: { Authorization: auth.code },
                onDownloadProgress: (progressEvent) => {
                  let progresspercent = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  console.log(this.percentCompleted);
                  this.namedowload = currentfile.filename;
                  if (progresspercent === 95) {
                    this.percentCompleted = 95;
                  } else {
                    this.percentCompleted = progresspercent;
                  }
                  this.percent = this.percentCompleted;
                },
                withCredentials: false,
                responseType: "arraybuffer",
              })
              .then((response) => {
                if (response.statusText === "OK") {
                  if (
                    parseInt(response.headers["content-lenght"]) < 700 &&
                    parseInt(response.headers["content-type"]) ===
                      "application/json"
                  ) {
                    this.opensnackbarmultidownload = false;
                    let res_error = gbfdecodearraybuffer.decodearraybuffer(
                      response.data
                    );
                    Toast.fire({
                      icon: "error",
                      title: res_error.errorMessage,
                    });
                  } else {
                    this.percentCompleted = 100;
                    this.percent = this.percentCompleted;
                    this.btsdownload = false;

                    let headers = response.headers;
                    let blob = new Blob([response.data], {
                      type: headers["content-type"],
                    });
                    // let link = document.createElement("a");
                    // link.href = window.URL.createObjectURL(blob);
                    // link.download = currentfile.filename;
                    // link.click();

                    // this.namedowload = currentfile.file_name;
                    // const blob = new Blob([response.data]);
                    // const content = response.headers["content-type"];
                    saveAs(blob, currentfile.filename);

                    setTimeout(() => {
                      this.opensnackbarmultidownload = false;
                      this.opensnackbar = false;
                    }, 2500);
                  }
                } else {
                  this.opensnackbarmultidownload = false;
                }
              })
              .catch((error) => {
                this.btsdownload = false;
                this.opensnackbarmultidownload = true;
                setTimeout(() => {
                  this.opensnackbarmultidownload = false;
                }, 3000);
                Toast.fire({
                  icon: "error",
                  title:
                    "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้",
                });
                console.log(error);
              });
          } else {
            this.opensnackbardownloadfile = true;
            this.btsdownload = true;
            let timeout = checkfilesize * 4000;
            window.location.assign(url);
            setTimeout(() => {
              this.opensnackbardownloadfile = false;
            }, timeout);
          }
        }
      }
    },
    async checkpreview() {
      // let folder_shared = localStorage.getItem('data_id')
      let folder_shared = CryptoJS.AES.decrypt(
        localStorage.getItem("data_id"),
        CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY),
        { mode: CryptoJS.mode.ECB }
      );
      folder_shared = window.atob(folder_shared.toString(CryptoJS.enc.Utf8));
      // OLD let token = localStorage.getItem("token") ? localStorage.getItem("token") : sessionStorage.getItem("token") ? sessionStorage.getItem("token") : VueCookies.get("token");
      let token = VueCookies.get("token") ? VueCookies.get("token") : sessionStorage.getItem("token") ? sessionStorage.getItem("token") : VueCookies.get("token");
      let token_ = token ? window.atob(token) : "";
      // let token_ = (token = window.atob(token));
      let payload = {
        folder_shared: folder_shared,
        access_token: token_,
      };
      try {
        let auth = await gbfGenerate.generateToken();
        var response = await this.axios.post(
          // process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/get_share_file_link",
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/share_link/check_sharing",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        );
        if (response.data.status === "OK") {
          console.log(response);

          this.check_file();
        } else if (response.data.errorCode === "ER502") {
          this.loading = false;
          this.havefile = false;
          this.havefolder = false;
        } else {
          Toast.fire({
            icon: "error",
            title: response.data.errorCode + ":" + response.data.errorMessage,
          });
        }
      } catch (ex) {
        console.log(ex);
        this.loading = false;
        this.havefile = false;
        this.havefolder = false;
      }
    },
    async checkdirectory(item) {
      // let folder_shared = localStorage.getItem('data_id')
      let folder_shared = CryptoJS.AES.decrypt(
        localStorage.getItem("data_id"),
        CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY),
        { mode: CryptoJS.mode.ECB }
      );
      folder_shared = window.atob(folder_shared.toString(CryptoJS.enc.Utf8));
      // OLD let token = localStorage.getItem("token") ? localStorage.getItem("token") : sessionStorage.getItem("token") ? sessionStorage.getItem("token") : VueCookies.get("token");
      let token = VueCookies.get("token") ? VueCookies.get("token") : sessionStorage.getItem("token") ? sessionStorage.getItem("token") : VueCookies.get("token");
      let token_ = token ? window.atob(token) : "";
      // let token_ = (token = window.atob(token));
      let payload = {
        folder_shared: folder_shared,
        access_token: token_,
      };
      try {
        let auth = await gbfGenerate.generateToken();
        var response = await this.axios.post(
          // process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/get_share_file_link",
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/share_link/check_sharing",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        );
        if (response.data.status === "OK") {
          console.log(response);

          this.gotodirectory(item);
        } else if (response.data.errorCode === "ER502") {
          this.loading = false;
          this.havefile = false;
          this.havefolder = false;
        } else {
          Toast.fire({
            icon: "error",
            title: response.data.errorCode + ":" + response.data.errorMessage,
          });
        }
      } catch (ex) {
        console.log(ex);
        this.loading = false;
        this.havefile = false;
        this.havefolder = false;
      }
    },
    async getfiledetail() {
      console.log("ff");
      // OLD let token = localStorage.getItem("token") ? localStorage.getItem("token") : sessionStorage.getItem("token") ? sessionStorage.getItem("token") : VueCookies.get("token");
      let token = VueCookies.get("token") ? VueCookies.get("token") : sessionStorage.getItem("token") ? sessionStorage.getItem("token") : VueCookies.get("token");
      let token_ = token ? window.atob(token) : "";
      // let token_ = (token = window.atob(token));
      console.log("token", token);
      let payload = {
        // access_token:token,
        access_token: token_,
        link: this.$route.query.id,
        password: this.password,
      };
      try {
        let auth = await gbfGenerate.generateToken();
        var response = await this.axios.post(
          // process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/get_share_file_link",
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/get_shared_data_link",
          payload,
          {
            headers: { Authorization: auth.code },
            withCredentials: false,
          }
        );

        if (response.data.status === "OK") {
          console.log("llll");
          this.namefile_folder = response.data.data["data_name"];
          this.clickloading = false;
          this.opencheckpassword = false;
          this.file_type = response.data.data["data_type"];
          // localStorage.setItem("data_id", response.data.data["data_id"]);
          localStorage.setItem(
            "data_id",
            CryptoJS.AES.encrypt(
              window.btoa(response.data.data["data_id"]),
              CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY),
              { mode: CryptoJS.mode.ECB }
            ).toString()
          );
          this.loading = false;
          if (response.data.data.status_share === "P") {
            this.permission = response.data.data.private_permission;
          } else {
            if (response.data.data.permission_status === "Y") {
              this.permission = response.data.data.permission;
            } else {
              this.permission = "02";
            }
          }
          console.log("ff", this.permission);
          if (this.file_type === "file") {
            this.havefile = true;
            this.currentopendate  = format(new Date(), "dd/MM/yyyy HH:mm");
            this.filedata = {
              business_shared_th: response.data.data["business_shared_th"],
              business_shared_eng: response.data.data["business_shared_eng"],
              user_id: "Shared file",
              filename: response.data.data["data_name"],
              size_file: response.data.data["size"],
              id: response.data.data["data_id"],
              business_id: response.data.data["business_id"],
              status_share: response.data.data["status_share"],
              permission_share_link: this.permission,
              status_share_link: response.data.data["status_share"],
              name_shared_th: response.data.data["name_shared_th"],
              name_shared_eng: response.data.data["name_shared_eng"],
              last_dtm: this.formatdatetime(response.data.data["last_dtm"]),
              status_watermark: response.data.data["status_watermark"],
              status_private_share: response.data.data["private_share"],
              currentopendate: this.currentopendate
            };
            response.data.data["private_share"] === true ? this.fullname = [response.data.data["name_shared_th"], response.data.data["name_shared_eng"]] : "";
            // console.log('this.filedata',this.filedata);
            this.checkfilename = this.filedata.filename.substring(
              this.filedata.filename.length - 3,
              this.filedata.filename.length
            );
            //console.log("นี่",this.checkfilename)
          } else if (this.file_type === "folder") {
            this.havefolder = true;
            //this.loadingdata = true;
            this.currentopendate  = format(new Date(), "dd/MM/yyyy HH:mm");
            this.folder_share = {
              ...response.data.data,
              permission_share_link: this.permission,
              currentopendate: this.currentopendate,
              last_dtm: this.formatdatetime(response.data.data["last_dtm"]),
            }
            response.data.data["private_share"] === true ? this.fullname = [response.data.data["name_shared_th"], response.data.data["name_shared_eng"]] : "";
            this.mainfolder = response.data.data["data_id"];
            this.loadfile_folder(this.mainfolder);
            console.log("this.folder_share", this.folder_share);
          }
        } else if (response.data.errorCode === "ER100") {
          //มีรหัสผ่าน
          this.opencheckpassword = true;
          console.log(this.opencheckpassword);
          this.loading = false;
          // Toast.fire({
          //   icon: "success",
          //   title: "เปิดไดลอคเช็ค password",
          // });
        } else if (response.data.errorCode === "ER105") {
          //รหัสผ่านผิด
          this.clickloading = false;
          this.password = "";

          Toast.fire({
            icon: "error",
            title: response.data.errorCode + ":" + response.data.errorMessage,
          });
          // เพิ่มตรงนี้ (หากผิดพลาด สามารถลบได้ตรงนี้)
        } else if (response.data.errorCode === "ER302" || response.data.errorCode === "ER303") {
          //ไฟล์/โฟลเดอร์ถูกลบไปแล้ว
          this.data_removed = true,
          this.loading = false;
          this.havefile = false;
          this.havefolder = false;
          this.permission_denied = false;
          ///////////////////////////////////////
        } else if (response.data.errorCode === "ER501") {
          //ครบจำนวนครั้งที่ให้แชร์
          console.log("เข้าาาาาาา");
          this.data_removed = false // เพิ่มตรงนี้ (หากผิดพลาด สามารถลบได้ตรงนี้)
          this.timeout = true;
          this.loading = false;
          this.havefile = false;
          this.havefolder = false;
          this.clickloading = false;
          this.opencheckpassword = false;
          this.permission_denied = false;
        } else if (response.data.errorCode === "ER403") {
          //หมดเวลาแชร์
          // console.log("เข้าาาาาาา");
          this.data_removed = false // เพิ่มตรงนี้ (หากผิดพลาด สามารถลบได้ตรงนี้)
          this.set_time = true;
          this.loading = false;
          this.havefile = false;
          this.havefolder = false;
          this.permission_denied = false;
        } else if (response.data.errorCode === "ER999") {
          if (response.data.errorMessage === "You cannot access this link" || response.data.errorMessage === "You cannot access this link because your link has expired") {
            this.data_removed = false // เพิ่มตรงนี้ (หากผิดพลาด สามารถลบได้ตรงนี้)
            this.set_time = false;
            this.loading = false;
            this.havefile = false;
            this.havefolder = false;
            this.permission_denied = true;
            this.token_unavailable = false;
          } else if (response.data.errorMessage === "Accesstoken หมดอายุ") {
            this.data_removed = false // เพิ่มตรงนี้ (หากผิดพลาด สามารถลบได้ตรงนี้)
            this.loading = false;
            this.havefile = false;
            this.havefolder = false;
            this.permission_denied = false;
            this.token_unavailable = true;
          }
        } else {
          this.loading = false;
          this.havefile = false;
          this.havefolder = false;
          this.permission_denied = false;
          console.log(this.havefolder, this.havefile);
          //   Toast.fire({
          //     icon: "error",
          //     title: response.data.errorCode + ":" + response.data.errorMessage,
          //   });
        }
      } catch (ex) {
        console.log(ex);
        this.loading = false;
        this.havefile = false;
        this.havefolder = false;
      }
    },
    async loadfile_folder(id) {
      this.datafolder = [];
      let cal_limit = this.size;
      let cal_offset = this.size * (this.page - 1);
      console.log(cal_limit, cal_offset, this.size, this.page);
      if (cal_offset == 0) {
        this.datafolder = [];
      }
      //let folder_shared = localStorage.getItem("data_id");
      let payload = {
        //folder_shared:folder_shared,
        data_id: id,
        limit: cal_limit,
        offset: cal_offset,
      };

      try {
        let auth = await gbfGenerate.generateToken();
        var response = await this.axios.post(
          // process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/get_share_file_link",
          process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/share_link/get_data",
          payload,
          {
            headers: { Authorization: auth.code },
            withCredentials: false,
          }
        );
        //console.log(response.data.status);
        if (response.data.status === "OK") {
          this.your_folder_id = response.data.your_folder[0]["folder_id"];
          console.log(response);
          this.loading = false;
          this.loadingdata = false;
          let checknavigate = this.navigate.findIndex((y) => y.text[1] === id);
          if (checknavigate === -1) {
            this.navigate.push({
              text: [
                response.data.your_folder[0]["folder_name"],
                response.data.your_folder[0]["folder_id"],
              ],
              disabled: false,
            });
          } else {
            let datanavigate = this.navigate;
            let i;
            this.navigate = [];
            for (i = 0; i <= checknavigate; i++) {
              this.navigate.push(datanavigate[i]);
            }
          }
          console.log("b", this.navigate);
          this.countfolder = response.data.total_folder;
          this.countfile = response.data.total_file;
          this.total_data = response.data.total;
          for (let i = 0; i < response.data.data.length; i++) {
            let datafolder = {};
            datafolder["file_id"] = response.data.data[i]["id"];
            datafolder["file_name"] = response.data.data[i]["name"];
            datafolder["file_size"] = response.data.data[i]["size"];
            datafolder["file_date"] = response.data.data[i]["last_dtm"];
            datafolder["file_linkshare"] = "-";
            datafolder["file_count_in"] =
              response.data.data[i]["counf_file_in"];
            datafolder["last_dtm"] = "-";
            datafolder["file_status_share"] = "-";
            datafolder["priority"] = response.data.data[i]["priority"];
            datafolder["file_status_sharelink"] = "-";
            datafolder["file_status"] = "-";
            datafolder["file_owner_eng"] = "-";
            datafolder["file_owner_th"] = "-";
            datafolder["status_watermark"] = this.folder_share["status_watermark"];
            if (response.data.data[i]["data_type"] === "folder") {
              datafolder["file_type"] = "folder";
              datafolder["file_icon"] = ["folder", "#ffc107"];
            } else {
              datafolder["file_type"] = response.data.data[i]["data_type"];
              datafolder["file_icon"] = this.seticon(
                response.data.data[i]["data_type"]
              );
            }

            this.datafolder.push(datafolder);
          }
          console.log(this.datafolder);
        } else if (response.data.errorCode === "ER502") {
          this.loading = false;
          this.havefile = false;
          this.havefolder = false;
        }
        // else{
        //     Toast.fire({
        //     icon: "error",
        //     title: response.data.errorCode + ":" + response.data.errorMessage,
        //   });
        // }
      } catch (ex) {
        this.loading = false;
        this.havefile = false;
        this.havefolder = false;
        console.log(ex);
      }
    },
    totalfiles() {
      let i;
      let total = 0;
      for (i = 0; i < this.datafolder.length; i++) {
        total += this.datafolder[i]["file_count_in"];
      }

      return total + this.countfile;
    },
    seticon(_type) {
      let dataicon;
      let parameter;
      if (typeof _type === "string") {
        parameter = _type.toLowerCase();
      } else {
        parameter = _type;
      }
      console.log("parameterparameter",parameter);
      if (parameter === "xlsx" || parameter === "xls") {
        // dataicon = ["mdi-file-excel", "green"];
        dataicon = ["mdi-file-excel", "#00733b"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        // dataicon = ["mdi-file-powerpoint", "orange"];
        dataicon = ["mdi-file-powerpoint", "#e03303"];
      } else if (parameter === "docx" || parameter === "doc") {
        // dataicon = ["mdi-file-word", "primary"];
        dataicon = ["mdi-file-word", "#0263d1"];
      } else if (parameter === "zip") {
        dataicon = ["mdi-zip-box", "blue-grey"];
      } else if (parameter === "pdf") {
        //  dataicon = ["mdi-file-pdf", "red"];
        dataicon = ["mdi-file-pdf", "#e5252a"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text", "blue-grey"];
      } else if (parameter === "csv") {
        dataicon = ["mdi-file-table", "green"];
      } else if (parameter === "jpeg" || parameter === "jpg" || parameter === "png" || parameter === "gif") {
        // dataicon = ["mdi-file-image", "blue-grey"];
        dataicon = ["mdi-file-image", "#0ac963"];
      } else if (
        parameter === "avi" ||
        parameter === "mpeg" ||
        parameter === "mov" ||
        parameter === "mp4" ||
        parameter === "mkv" ||
        parameter === "wmv" ||
        parameter === "3gp" ||
        parameter === "flv"
      ) {
        dataicon = ["mdi-file-video", "purple"];
      } else if (parameter === "shortcut") {
        dataicon = ["mdi-file-link", "pink"];
      } else if (parameter === "r" || parameter === "folder") {
        // dataicon = ["mdi-folder", "#FDD361"];
        dataicon = ["mdi-folder", "#ffc107"];
      }
      else {
        dataicon = ["mdi-file", "black"];
      }
      return dataicon;
    },
    async downloadall(_folder) {
      this.btsdownload = true;
      this.percent = 0;
      this.listdatadowload.push(this.folder_share);
      this.newlist = [];
      let datadowload = {};
      for (let i = 0; i < this.listdatadowload.length; i++) {
        datadowload["fileid"] = this.listdatadowload[i].file_id;
        datadowload["name"] = this.listdatadowload[i].file_name;
        datadowload["value"] = 0;
        // console.log(datadowload);
      }

      this.newlist.push(datadowload);

      for (let i = 0; i < this.listdatadowload.length; i++) {
        this.dataIndex = this.newlist.findIndex(
          (obj) => obj.name === this.listdatadowload[i].file_name
        );
      }
      console.log(this.dataIndex);
      this.opensnackbarmultidownload = true;
      // this.snackbardowload = true;
      this.namedowload = this.namefile_folder;
      this.new_list = this.newlist;
      let timeout = 4000;

      let all_file = [];
      let zip_name = "";
      let url_link;
      console.log(_folder);
      // let url =
      //   process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
      //   "/api/request/download_folder?account_id=" +
      //   "Shared link" +
      //   "&folder_id=" +
      //   _folder;

      // this.axios
      //   .get(url, {})
      //   .then((response) => {
      //     //console.log(response.data.status);
      //     if (response.data.status === "OK") {
      //       this.fn_loop_downloading(
      //         response.data.all_file,
      //         response.data.zip_name,
      //         response.data.url
      //       );
      //     } else {
      //       this.btsdownload = false;
      //       this.opensnackbarmultidownload = false;
      //       Toast.fire({
      //         icon: "error",
      //         title:
      //           "ไม่สามารถดาวน์โหลด " + this.folder_share.data_name + " นี้ได้",
      //       });
      //     }
      //   })
      //   .catch((error) => {
      //     this.btsdownload = false;
      //     this.opensnackbarmultidownload = false;
      //     Toast.fire({
      //       icon: "error",
      //       title:
      //         "ไม่สามารถดาวน์โหลด " +
      //         this.namefile_folder.data_name +
      //         " นี้ได้",
      //     });
      //     console.log("err", error);
      //   });

      // this.btsdownload = true;
      //  console.log("naviiiii");
      // this.percentCompleted = 0;
      let url;
      if (this.folder_share["status_watermark"] === "N") {
        url =
          process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
          "/api/v2/download_folder?account_id=" +
          "Shared link" +
          "&folder_id=" +
          _folder;
      } else {
        url =
          process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
          "/api/v1/share_link/download_folder?folder_id=" +
          _folder +
          "&language=" +
          this.$t("default");
        if (this.filedata.status_private_share === true || this.folder_share.private_share === true) {
          url = url + "&account_id=" + this.dataCitizenProfile.account_id;;
        }
      }
      // this.snackbardowloadfolder = true;
      this.opensnackbarmultidownload = true;
      this.namedowload = this.navigate[0].text[0];
      let auth = await gbfGenerate.generateToken();
      this.axios
        .get(url, {
          headers: { Authorization: auth.code },
          onDownloadProgress: (progressEvent) => {
            // console.log(progressEvent);
            let progresspercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            // console.log(progresspercent);
            if (progresspercent === 95) {
              this.percentCompleted = 95;
            } else {
              this.percentCompleted = progresspercent;
            }
            this.percent = this.percentCompleted;
          },
          withCredentials: false,
          responseType: "arraybuffer",
        })
        .then((response) => {
      if (response.statusText === "OK") {
        console.log("response downloadall",response);
        if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
          this.opensnackbarmultidownload = false;
          let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
          console.log("res_error downloadall",res_error);
          Toast.fire({ icon: "error", title: res_error.errorMessage });
        } else {
          this.percentCompleted = 100;
          this.btsdownload = false;

          let headers = response.headers;
          let blob = new Blob([response.data],{type:headers['content-type']});
          // let link = document.createElement("a");
          // link.href = window.URL.createObjectURL(blob);
          // link.download = this.navigate[0].text[0];
          // link.click();
          // const blob = new Blob([response.data]);
          // const content = response.headers["content-type"];
          saveAs(blob, this.navigate[0].text[0] + ".zip");

          setTimeout(() => {
            this.opensnackbarmultidownload = false;
          }, 2500);
        }
      }else{
        this.opensnackbarmultidownload = false;
      }
      // this.clearmultipledownload();
      })
      .catch((error) => {
        this.btsdownload = false;
        console.log(error);
        Toast.fire({
          icon: "error",
          title: "ไม่สามารถดาวน์โหลด " + this.namedowload + " นี้ได้",
        });
        console.log(error);
      });
    },
    fn_calculate_size(_sizefilebyte) {
      console.log("ขนาดไฟล์เป็น GB", (_sizefilebyte / 1073741824).toFixed(2));
      return parseFloat((_sizefilebyte / 1073741824).toFixed(2));
      // return gb_sizeDataConverter.convertStorageToKbMbGb(_sizefilebyte);
    },
    fn_calculate_size_new(_sizefilebyte) {
      return gb_sizeDataConverter.convertStorageToKbMbGb(_sizefilebyte);
    },
    async downloadfile(currentfile) {
      console.log(currentfile);
      this.percent = 0;
      let checkfilesize = this.fn_calculate_size(currentfile.size_file);
      if (currentfile.file_type === "folder") {
        Toast.fire({
          type: "warning",
          title: "Coming Soon !!",
        });
      } else {
        console.log(currentfile.id);
        // let payload = {
        //   account_id: this.dataAccountId,
        //   user_id: currentfile.user_id,
        //   file_id: currentfile.id,
        // };
        if (currentfile["status_watermark"] === "N") {
          url =
            process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
            "/api/v2/download_file?user_id=" +
            currentfile.user_id +
            "&file_id=" +
            currentfile.id;
        } else {
          url = 
            process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + 
            "/api/v1/share_link/download_file?file_id="+ 
            currentfile.id +
            "&language=" +
            this.$t("default") +
            "&link=" +
            this.$route.query.id;
          if (this.filedata.status_private_share === true || this.folder_share.private_share === true) {
            url = url + "&account_id=" + this.dataCitizenProfile.account_id;;
          }
        }
        if (checkfilesize < 1.0) {
          console.log(this.opensnackbarmultidownload);

          this.opensnackbarmultidownload = true;
          this.namedowload = currentfile.filename;
          let auth = await gbfGenerate.generateToken();
          this.axios
            .get(url, {
              headers: { Authorization: auth.code },
              onDownloadProgress: (progressEvent) => {
                let progresspercent = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                console.log(progressEvent.loaded, progressEvent.total);
                // this.namedowload = currentfile.filename;
                if (progresspercent === 95) {
                  this.percentCompleted = 95;
                } else {
                  this.percentCompleted = progresspercent;
                }
                console.log(this.percentCompleted, progresspercent);
                this.percent = this.percentCompleted;
              },
              withCredentials: false,
              responseType: "arraybuffer",
            })
            .then((response) => {
              if (response.statusText === "OK") {
                this.snackbardowload = false;
                console.log("downloadfile", response);
                if (
                  parseInt(response.headers["content-length"]) < 700 &&
                  parseInt(response.headers["content-type"]) ===
                    "application/json"
                ) {
                  this.opensnackbarmultidownload = false;
                  let res_error = gbfdecodearraybuffer.decodearraybuffer(
                    response.data
                  );
                  console.log("res_error downloadfile", res_error);
                  Toast.fire({ icon: "error", title: res_error.errorMessage });
                } else {
                  this.percentCompleted = 100;
                  this.btsdownload = false;
                  this.percent = 100;
                  let headers = response.headers;
                  let blob = new Blob([response.data], {
                    type: headers["content-type"],
                  });
                  // let link = document.createElement("a");
                  // link.href = window.URL.createObjectURL(blob);
                  // link.download = currentfile.filename;
                  // link.click();
                  // const blob = new Blob([response.data]);
                  // const content = response.headers["content-type"];
                  saveAs(blob, currentfile.filename);
                  // this.snackbardowload = false;

                  setTimeout(() => {
                    this.btsdownload = false;
                    this.opensnackbarmultidownload = false;
                  }, 2500);
                }
              } else {
                this.btsdownload = false;
                this.opensnackbarmultidownload = false;
              }
            })
            .catch((error) => {
              this.btsdownload = false;
              this.opensnackbarmultidownload = false;
              Toast.fire({
                icon: "error",
                title: "ไม่สามารถดาวน์โหลด " + currentfile.filename + " นี้ได้",
              });
              console.log(error);
            });
        } else {
          this.opensnackbarmultidownload = true;
          this.btsdownload = true;
          let timeout = checkfilesize * 4000;
          window.location.assign(url);
          setTimeout(() => {
            this.opensnackbarmultidownload = false;
          }, timeout);
        }
      }
    },
    async fn_loop_downloading(all_file, zip_name, url_link) {
      let total = 0;
      this.btsdownload = true;
      if (all_file.length === 0) {
        this.newlist[this.dataIndex]["value"] = 100;
        //console.log(url_link);
        window.location.assign(url_link);
        this.opensnackbarmultidownload = false;
      } else {
        for (let i = 0; i < all_file.length; i++) {
          //console.log("all_file.length",(1/all_file.length)*100,total);

          total += (1 / all_file.length) * 100;
          let res = await this.downloading_folder(
            all_file[i]["file_id"],
            all_file[i]["location_file"],
            zip_name,
            all_file[i]["path"]
          );
          //console.log(res);
          if (this.newlist[this.dataIndex]["value"] > 99) {
            this.newlist[this.dataIndex]["value"] = 99;
            this.percent = 99;
          } else {
            this.newlist[this.dataIndex]["value"] = Math.round(total);
            this.percent = Math.round(total);
          }
          this.newpercen = this.newlist[this.dataIndex]["value"];
          this.namefile = this.newlist[this.dataIndex]["name"];
          //console.log(this.percentCompleted);
          // if(res.status === "OK" ){

          // }else{
          //   //continue;
          // }
          //console.log(i,(all_file.length)-1);
          if (i === all_file.length - 1) {
            this.percent = 100;
            this.newlist[this.dataIndex]["value"] = 100;
            //console.log(url_link);
            window.location.assign(url_link);
            this.btsdownload = false;
            this.opensnackbarmultidownload = false;
            //            this.axios.get(url_link, {})
            //  .then((response) => {
            //   console.log(response);
            // });
          }
        }
      }
    },
    downloading_folder(file_id, location_file, zip_name, all_file) {
      let url =
        process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
        "/api/downloading_file?file_id=" +
        file_id +
        "&location_file=" +
        location_file +
        "&zip_name=" +
        zip_name +
        "&path=" +
        all_file;

      //console.log(url);
      return new Promise((resolve, reject) => {
        this.axios.get(url, {}).then((response) => {
          //console.log("testtt",response.data.status);
          if (response.data.status !== "OK") {
            //  Toast.fire({
            //       icon: "error",
            //       title: response.data.errorCode+": "+response.data.errorMessage,
            //     });
          }
          setTimeout(() => {
            resolve({
              status: response.data.status,
              msg: "",
            });
          }, 1);

          // }else{
          //    return new Promise((resolve, reject) => {
          // setTimeout(() => {
          //       resolve({
          //         status: response.data.status,
          //         msg: "",
          //       });
          //     }, 1000);
          //       });
          // }
        });
      });
    },
    async downloadfile_folder(_file) {
      //console.log(_file, _file.file_id);
      this.btsdownload = true;
      this.percentCompleted = 0;
      this.percent = 0;
      this.listdatadowload.push(_file);
      this.newlist = [];
      let datadowload = {};
      for (let i = 0; i < this.listdatadowload.length; i++) {
        datadowload["fileid"] = this.listdatadowload[i].file_id;
        datadowload["name"] = this.listdatadowload[i].file_name;
        datadowload["value"] = 0;
        // console.log(datadowload);
      }

      this.newlist.push(datadowload);
      if (_file.file_type === "folder") {
        for (let i = 0; i < this.listdatadowload.length; i++) {
          this.dataIndex = this.newlist.findIndex(
            (obj) => obj.name === this.listdatadowload[i].file_name
          );
        }
        console.log(this.dataIndex);
        this.opensnackbarmultidownload = true;

        this.namedowload = _file.file_name;
        this.new_list = this.newlist;
        let timeout = 4000;

        let all_file = [];
        let zip_name = "";
        let url_link;

        // let url =
        //   process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
        //   "/api/request/download_folder?account_id=" +
        //   "Shared link" +
        //   "&folder_id=" +
        //   _file.file_id;

        // this.axios
        //   .get(url, {})
        //   .then((response) => {
        //     //console.log(response.data.status);
        //     if (response.data.status === "OK") {
        //       this.fn_loop_downloading(
        //         response.data.all_file,
        //         response.data.zip_name,
        //         response.data.url
        //       );
        //     } else {
        //       this.btsdownload = false;
        //       this.opensnackbarmultidownload = false;
        //       Toast.fire({
        //         icon: "error",
        //         title:
        //           "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้",
        //       });
        //     }
        //   })
        //   .catch((error) => {
        //     this.btsdownload = false;
        //     this.opensnackbarmultidownload = false;
        //     Toast.fire({
        //       icon: "error",
        //       title: "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้",
        //     });
        //     console.log("err", error);
        //   });
        // let url =
        //   process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
        //   "/api/v2/download_folder?account_id=" +
        //   "Shared link" +
        //   "&folder_id=" +
        //   _file.file_id;
        // this.snackbardowload = true;
        let url;
        if (_file["status_watermark"] === "N") {
          url =
            process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
            "/api/v2/download_folder?account_id=" + +
            "Shared link" +
            "&folder_id=" +
            _file.file_id;
        } else {
          url =
            process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
            "/api/v1/share_link/download_folder?folder_id=" +
            _file.file_id +
            "&language=" +
            this.$t("default");
          if (this.filedata.status_private_share === true || this.folder_share.private_share === true) {
            url = url + "&account_id=" + this.dataCitizenProfile.account_id;;
          }
        }
        this.opensnackbarmultidownload = true;
        this.namedowload = _file.file_name;
        let auth = await gbfGenerate.generateToken();
        this.axios
          .get(url, {
            headers: { Authorization: auth.code },
            onDownloadProgress: (progressEvent) => {
              // console.log(progressEvent);
              let progresspercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              console.log(this.percentCompleted);
              // this.namedowload = _file.file_name;
              if (progresspercent === 95) {
                this.percentCompleted = 95;
              } else {
                this.percentCompleted = progresspercent;
              }
              this.percent = this.percentCompleted;
            },
            withCredentials: false,
            responseType: "arraybuffer",
          })
          .then((response) => {
            if (response.statusText === "OK") {
              console.log("downloadfile_folder",response);
              if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
                this.opensnackbarmultidownload = false;
                let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
                console.log("res_error downloadfile_folder",res_error);
                Toast.fire({ icon: "error", title: res_error.errorMessage });
              } else {
                this.percentCompleted = 100;
                this.btsdownload = false;

                let headers = response.headers;
                let blob = new Blob([response.data],{type:headers['content-type']});
                // let link = document.createElement("a");
                // link.href = window.URL.createObjectURL(blob);
                // link.download = _file.file_name;
                // link.click();
                // const blob = new Blob([response.data]);
                // const content = response.headers["content-type"];
                saveAs(blob, _file.file_name + ".zip");

                // setTimeout(() => {
                //   this.opensnackbarmultidownload = false;
                // }, 2500);
              }
              setTimeout(() => {
                this.opensnackbarmultidownload = false;
              }, 2500);
            }else{
              this.opensnackbarmultidownload = false;
            }
        })
        .catch((error) => {
          this.btsdownload = false;
          this.opensnackbarmultidownload = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถดาวน์โหลด " + _file.file_name + " นี้ได้",
          });
          console.log(error);
        });
      } else {
        let url;
        if (_file["status_watermark"] === "N") {
          url =
            process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
            "/api/v2/download_file?user_id=" +
            "Shared link" +
            "&file_id=" +
            _file.file_id;
        } else {
          url = 
            process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + 
            "/api/v1/share_link/download_file?file_id="+ 
            _file.file_id +
            "&language=" +
            this.$t("default") +
            "&link=" +
            this.$route.query.id;
          if (this.filedata.status_private_share === true || this.folder_share.private_share === true) {
            url = url + "&account_id=" + this.dataCitizenProfile.account_id;;
          }
        }
        // this.snackbardowload = true;
        this.opensnackbarmultidownload = true;
        this.namedowload = _file.file_name;
        let auth = await gbfGenerate.generateToken();
        this.axios
          .get(url, {
            headers: { Authorization: auth.code },
            onDownloadProgress: (progressEvent) => {
              let progresspercent = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              //console.log(this.percentCompleted);
              this.namedowload = _file.file_name;
              if (progresspercent === 95) {
                this.percentCompleted = 95;
              } else {
                this.percentCompleted = progresspercent;
              }
              this.percent = this.percentCompleted;
            },
            withCredentials: false,
            responseType: "arraybuffer",
          })
          .then((response) => {
            if (response.statusText === "OK") {
              console.log("downloadfile_folder response file", response);
              if (
                parseInt(response.headers["content-length"]) < 700 &&
                parseInt(response.headers["content-type"]) ===
                  "application/json"
              ) {
                this.opensnackbarmultidownload = false;
                let res_error = gbfdecodearraybuffer.decodearraybuffer(
                  response.data
                );
                console.log("downloadfile_folder file res_error", res_error);
                Toast.fire({
                  icon: "error",
                  title: res_error.errorMessage,
                });
              } else {
                this.percentCompleted = 100;
                this.btsdownload = false;

                let headers = response.headers;
                let blob = new Blob([response.data], {
                  type: headers["content-type"],
                });
                // let link = document.createElement("a");
                // link.href = window.URL.createObjectURL(blob);
                // link.download = _file.file_name;
                // link.click();

                // const blob = new Blob([response.data]);
                // const content = response.headers["content-type"];
                saveAs(blob, _file.file_name);
                setTimeout(() => {
                  this.opensnackbarmultidownload = false;
                }, 2500);
              }
            } else {
              this.opensnackbarmultidownload = false;
            }
          })
          .catch((error) => {
            this.btsdownload = false;
            this.opensnackbarmultidownload = false;
            Toast.fire({
              icon: "error",
              title: "ไม่สามารถดาวน์โหลด " + _file.file_name + " นี้ได้",
            });
            console.log(error);
          });
      }
    },
    // downloadfile(currentfile) {
    //   if (currentfile.file_type === "folder") {
    //     Toast.fire({
    //       icon: "warning",
    //       title: "Coming Soon !!"
    //     });
    //   } else {
    //     let payload = {
    //       user_id: currentfile.user_id,
    //       file_id: currentfile.id
    //     };
    //     let url = process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/v2/download_file?user_id="+ currentfile.user_id + "&file_id=" + currentfile.id;

    //     const link = document.createElement("a")
    //     link.setAttribute("href", url); //or any other extension
    //     link.setAttribute("download", currentfile.file_name); //or any other extension
    //     document.body.appendChild(link);
    //     // document.body.removeChild(link);
    //     setTimeout(() => {
    //       link.click();
    //       // Cleanup the DOM
    //       document.body.removeChild(link);
    //     }, 500);
    //     // this.axios
    //     //   .post(
    //     //     process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/download_file",
    //     //     payload,
    //     //     {
    //     //       withCredentials: false,
    //     //       responseType: "blob"
    //     //     }
    //     //   )
    //     //   .then(response => {
    //     //     const url = window.URL.createObjectURL(new Blob([response.data]));
    //     //     const link = document.createElement("a");
    //     //     link.href = url;
    //     //     link.setAttribute("download", this.filedata["filename"]); //or any other extension
    //     //     document.body.appendChild(link);
    //     //     link.click();
    //     //   })
    //     //   .catch(error => {
    //     //     Toast.fire({
    //     //       icon: "error",
    //     //       title: "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้"
    //     //     });
    //     //     console.log(error);
    //     //   });
    //   }
    // },
    setformatdate(_datetime) {
      if (_datetime === "-") {
        return _datetime;
      } else {
        var year = _datetime.substring(0, 4);
        var month = _datetime.substring(4, 6);
        var day = _datetime.substring(6, 8);
        var time =
          _datetime.substring(8, 10) +
          ":" +
          _datetime.substring(10, 12) +
          ":" +
          _datetime.substring(12, 14);
        let alldate = year + "-" + month + "-" + day;
        return alldate + " " + time;
      }
    },
    gotofolder(_folderid) {
      this.loadingdata = true;
      this.datafolder = [];
      this.page = 1;
      this.loadfile_folder(_folderid);
    },
    gotodirectory(_folder) {
      if (_folder.file_type === "folder") {
        console.log(_folder);
        this.loadingdata = true;
        this.datafolder = [];
        this.page = 1;
        this.loadfile_folder(_folder.file_id);
      } else {
        this.currentfile_folder = _folder;
        this.openpreviewfile_folder = true;
      }
    },
    backtopreventfolder() {
      if (this.navigate.length === 1) {
        this.loadingdata = true;
        this.datafolder = [];
        this.page = 1;
        this.loadfile_folder(this.navigate[0]["text"][1]);
      } else {
        this.navigate.pop();
        this.loadingdata = true;
        this.datafolder = [];
        this.page = 1;
        this.loadfile_folder(
          this.navigate[this.navigate.length - 1]["text"][1]
        );
      }
    },
    // calculatesize(parameter) {
    //   let size;
    //   if (parameter >= 10000000000000 && parameter <= 1000000000000000) {
    //     size = (parameter / 10000000000000).toFixed(2) + " TB";
    //   } else if (parameter >= 1000000000 && parameter <= 1000000000000) {
    //     size = (parameter / 1000000000).toFixed(2) + " GB";
    //   } else if (parameter >= 1048576 && parameter <= 1000000000) {
    //     size = (parameter / 1000000).toFixed(2) + " MB";
    //   } else if (parameter >= 1000 && parameter <= 1000000) {
    //     size = (parameter / 1000).toFixed(2) + " KB";
    //   } else if (parameter === "-") {
    //     size = parameter;
    //   } else {
    //     size = parameter + " B";
    //   }

    //   return size;
    // },
    gotohomepage() {
      this.$router.push("/");
    },
    // เปลี่ยนภาษา
    changelang(parameter) {
      localStorage.setItem("lang", parameter);
      this.$i18n.locale = parameter;
      let dflag = this.language.findIndex((v) => v.data === parameter);
      this.defaultflag = this.language[dflag]["flag"];
    },
  },
  async mounted() {
    if (this.$route.query.id === "" || !this.$route.query.id) {
      this.loading = false;
    } else {
      await this.getfiledetail();
      // สำหรับ private share เพื่อ get profile ของคนเปิด
      if (this.filedata.status_private_share === true || this.folder_share.private_share === true) {
        this.$store
        .dispatch('authorize_checking')
        .then((msg) => {
          if (msg.status === "Access Denied") {
            if (VueCookies.get("token")) {
              this.$store.dispatch('authorize_checking')
            } else {
              if (sessionStorage.getItem("token")) {
                this.$store.dispatch('authorize_checking')
                sessionStorage.setItem("checklogin", false)
              } else {
                // OLD if (localStorage.getItem("token")) {
                if (VueCookies.get("token")) {
                  // OLD sessionStorage.setItem("token", localStorage.getItem("token"))
                  sessionStorage.setItem("token", VueCookies.get("token"))
                  sessionStorage.setItem("token_auth", "true")
                  sessionStorage.setItem("checklogin", "false")
                  this.$store.dispatch('authorize_checking');
                } else {
                  this.data_removed = false
                  this.loading = false;
                  this.havefile = false;
                  this.havefolder = false;
                  this.permission_denied = false;
                  this.token_unavailable = true;
                }
              }
            }
          }
        });
      }
    }
    sessionStorage.removeItem("onechat_token");
    sessionStorage.removeItem("onechat_plugin_enable");
    this.changelang(localStorage.getItem("lang"));
    //console.log("onechat_token",sessionStorage.getItem("onechat_token"));
  },
};
</script>
<style>
.v-snack__content {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}
</style>
